import moment from 'moment';
import 'moment/dist/locale/ru';

/** Helper для работы с датой и временем. */
class dateTimeHelper {
    constructor() {
        moment.locale('ru');
    }

    /**
     * Возвращает дату строкой.
     * @param date Дата
     * @returns Дата строкой
     */
    public toDateString = (date: Date | undefined | null) : string => {
        if(!date) {
            return '';
        }
        return moment(date).format('DD.MM.YYYY');
    }

    /**
     * Возвращает дату строкой.
     * @param date Дата
     * @returns Дата строкой
     */
    public toDateTimeString = (date: Date | undefined) : string => {
        if(!date) {
            return '';
        }
        return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }
    
    /**
     * Возвращает дату.
     * @param string Дата строкой
     * @returns Дата
     */
    public toDate = (string: string | undefined | null) : Date | null => {
        if(!string) {
            return null;
        }
        return new Date(string);
    }

    /**
     * Возвращает строку с полной датой.
     * @param date Дата.
     * @returns Строка с полной датой.
     */
    public toFullDateString = (date: Date | undefined | null) : string => {
        if(!date) {
            return '';
        }
        
        try {
            return moment(date).format('DD MMMM YYYY');
        }
        catch(ex){
            return '';
        }
    }

    /**
     * Возвращает дату для работы с API.
     * @param date Дата
     * @returns 
     */
    public toDateOnly = (date: Date | undefined) : string => {
        if(!date) {
            return '';
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    /**
     * Возвращает время.
     * @param date Дата
     * @returns Время.
     */
    public toTimeString(date : Date | null) {
        if(!date) {
            return '';
        }

        return date.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Используем 24-часовой формат
        });
    }
};

export default new dateTimeHelper();